<template>
  <div class="mw8 center">
    <h1 v-if="mode === 'login'">Login</h1>
    <h1 v-if="mode === 'register'">Sign Up</h1>
    <div
      v-show="mode === 'login'"
      class="pa2 b-blue blue bw1 ba br1 dim pointer dib mv3"
      @click="setMode('register')"
    >
      Don't have an account? Click Here
    </div>
    <div
      v-show="mode === 'register'"
      class="pa2 b-blue blue bw1 ba br1 dim pointer dib mv3"
      @click="setMode('login')"
    >
      Already have an account? Click Here
    </div>

    <hr class="mv3 mh2 o-50" />

    <!-- Loader -->
    <div v-show="user === undefined" data-test="loader">Authenticating...</div>

    <!-- Offline instruction -->
    <div v-show="!networkOnLine" data-test="offline-instruction">
      Please check your connection, login feature is not available offline.
    </div>

    <p v-if="loginError" class="pa2 bg-light-yellow b">{{ loginError }}</p>
    <!-- Auth UI -->
    <div
      v-show="user !== undefined && !user && networkOnLine"
      data-test="login-btn"
      class="pa2 b-grey bw1 ba br1 dim pointer dib mv3"
      @click="login"
    >
      <span v-show="mode === 'login'">Login</span>
      <span v-show="mode === 'register'">Sign up</span> with google
    </div>
    <p class="pv2 i">
      By using your existing Google account, you can securely login to IAJESdb
      without creating or remembering another password. When using this method,
      your password will not be shared with IAJESdb.
    </p>

    <hr class="mv3 mh2 o-50" />

    <label for="email" class="b mv2 db">Email Address</label>
    <div v-show="$v.email.$invalid" class="red b f6">
      Please enter a valid email address.
    </div>
    <input
      id="email"
      v-model.trim="email"
      placeholder="email..."
      type="text"
      class="pa2 w-100 mv3"
    />
    <label for="password" class="b mv2 dib">Password</label>
    <div v-show="$v.password.$invalid" class="red b f6">
      Password must be at least 6 characters.
    </div>
    <input
      id="password"
      v-model.trim="password"
      placeholder="password..."
      type="password"
      class="pa2 w-100 mv3"
    />
    <label v-show="mode === 'register'" for="password2" class="b mv2 dib"
      >Repeat Password</label
    >
    <div v-show="mode === 'register' && $v.password2.$invalid" class="red b f6">
      Passwords must match
    </div>
    <input
      v-show="mode === 'register'"
      id="password2"
      v-model.trim="password2"
      placeholder="password..."
      type="password"
      class="pa2 w-100 mv3"
    />
    <div
      v-if="!$v.email.$invalid && !$v.password.$invalid"
      v-show="mode === 'login'"
      class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib mv3 mr2"
      @click="loginWithPassword()"
    >
      Log In with Password
    </div>
    <div
      v-if="!$v.$invalid"
      v-show="mode === 'register'"
      class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib mv3 mr2"
      @click="registerWithPassword()"
    >
      Sign Up with Password
    </div>
    <div
      v-show="!$v.email.$invalid && mode === 'login'"
      class="pa2 b-grey bw1 ba br1 dim pointer dib mv3 mr2"
      @click="resetPassword()"
    >
      Reset Password
    </div>
    <div
      v-if="mode === 'login' && ($v.email.$invalid || $v.password.$invalid)"
      class="pa2 b-grey bw1 ba br1 o-80 dib mv3"
    >
      Cannot Log In with Password Due to Errors
    </div>
    <div
      v-if="
        mode === 'register' &&
          ($v.email.$invalid || $v.password.$invalid || $v.password2.$invalid)
      "
      class="pa2 b-grey bw1 ba br1 o-80 dib mv3"
    >
      Cannot Sign Up with Password Due to Errors
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
import { desktop as isDekstop } from 'is_js'
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      loginError: null,
      mode: 'login',
      email: '',
      password: '',
      password2: ''
    }
  },
  head() {
    return {
      title: {
        inner: 'Login'
      },
      meta: [
        {
          name: 'description',
          content: `Sign in or sign up to ${this.appTitle}`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('users', ['users']),
    ...mapState('app', ['networkOnLine', 'appTitle'])
  },
  watch: {
    users: {
      handler(users) {
        if (!isNil(users)) {
          const { id } = this.user
          const getUserById = this.users.find(u => u.id === id)
          if (getUserById.firstRun) {
            this.$router.push(`/users/edit/${id}`)
          } else {
            this.$router.push('/home')
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('authentication', ['setUser']),
    setMode(mode) {
      this.mode = mode
    },
    async login() {
      this.loginError = null
      const provider = new firebase.auth.GoogleAuthProvider()
      this.setUser(undefined)

      try {
        // Firebase signin with popup is faster than redirect
        // but we can't use it on mobile because it's not well supported
        // when app is running as standalone on ios & android
        // eslint-disable-next-line no-unused-expressions
        isDekstop()
          ? await firebase.auth().signInWithPopup(provider)
          : await firebase.auth().signInWithRedirect(provider)
      } catch (err) {
        this.loginError = err
        this.setUser(null)
      }
    },
    async loginWithPassword() {
      this.loginError = null
      this.setUser(undefined)
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
      } catch (err) {
        this.loginError = err
        this.setUser(null)
      }
    },
    async registerWithPassword() {
      this.loginError = null
      this.setUser(undefined)
      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password)
      } catch (err) {
        this.loginError = err
        this.setUser(null)
      }
    },
    resetPassword() {
      this.loginError = null
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.loginError = 'Reset Email Sent'
        })
        .catch(err => {
          this.loginError = err
        })
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    password2: {
      required,
      sameAsPassword: sameAs('password')
    },
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss" scoped></style>
